.canteach-serma-project {
    &__form-wrapper {
        border: 5px #0058AB solid!important;
        margin: 1em auto!important;
        padding: 2em 1em!important;
        width: 100%!important;
        &,
        & * {
            box-sizing: border-box!important;
        }
    }
    &__title {
        font-size: 30px!important;
        margin: 0!important;
        &-icon {
            display: inline-block!important;
            height: 1.5em!important;
            width: 1.5em!important;
        }
    }
    &__table {
        border-collapse: collapse!important;
        font-size: .9em!important;
        thead th{
            background-color: #09a603!important;
            padding: .1em .5em!important;
        }
        tbody td {
            border-bottom: 1px rgb(221, 221, 221) solid!important;
            padding: .1em .5em!important;
            text-align: center!important;
        }
    }
    &__label {
        &-number {
            display: block!important;
            height: 2em!important;
            &::before {
                content: "残り"!important;
            }
            &::after {
                content: "席"!important;
            }
            &[data-id="0"]{
                position: relative!important;
                min-width: 5em!important;
                &::before {
                    background-color: #ff0004!important;
                    border-radius: 3px!important;
                    color: #fff!important;
                    content: "満席"!important;
                    display: block!important;
                    height: 100%!important;
                    left: 50%!important;
                    padding: .1em .25em!important;
                    position: absolute!important;
                    text-align: center!important;
                    top: 50%!important;
                    transform: translate(-50%,-50%)!important;
                    width: auto;
                }
                &::after {
                    display: none!important;
                }
            }
        }
    }
    &__footer {
        &-name,
        &-email {
            border-radius: 3px!important;
            border: 1px #09a603 solid!important;
            display: block!important;
            font-size: 24px!important;
            margin: .5em 0!important;
            outline: none!important;
            padding: .5em!important;
            &:focus {
                box-shadow: 0 0 3px #09a603 !important;
            }
        }
        &-name {
            max-width: 100%!important;
        }
        &-email {
            max-width: 100%!important;
            width: 450px!important;
        }
        &-btn {
            cursor: pointer!important;
            &:disabled {
                background: rgb(199, 199, 199)!important;
                cursor: not-allowed!important;
            }
        }
    }
}